import { ReactElement, useEffect, useRef, useState } from "react";
import useEventListener from "../../../hooks/useEventListener";
import { IATSButtons } from "../ATSButton";
import styles from "./ATSModal.module.scss";
import CancelWithCommentModal from "./CancelWithComment";
import CommentModal from "./Comment";
import DefaultModal from "./Default";
import LoadingModal from "./Loading";

interface IATSModalProps {
    title?: string;
    icon?: string;
    bodyText?: Array<string>;
    bodyTextType?: ATSBodyTextTypeModal;
    buttons?: Array<IATSButtons>;
    buttonsAlignment?: ATSButtonAlignmentModal;
    commentValue?: string;
    type: ATSTypeModal;
    handleClose: () => void;
    handleSetComment?: (value: any) => void;
    noClose?: boolean;
}

export enum ATSButtonAlignmentModal {
    CENTERED = "centered",
    LEFT = "left",
}

export enum ATSBodyTextTypeModal {
    SUBTITLE = "modal_subtitle",
}

export enum ATSTypeModal {
    DEFAULT = "default",
    COMMENT = "comment",
    CANCELWITHCOMMENT = "cancelWithComment",
    LOADING = "loading",
}

/**
 * Set new ATS modal component, a example-usage file was provided. See the component props for more information .
 * @param title: string -> optional;
 * @param icon: string -> optional;
 * @param bodyText: string -> optional;
 * @param bodyTextType: ATSBodyTextTypeModal -> optional ;
 * @param buttons: Array<IATSButtons> -> optional ;
 * @param buttonsAlignment: ATSButtonAlignmentModal -> optional;
 * @param type: ATSTypeModal;
 * @param handleClose: () => void;
 * @param handleSetComment: () => void -> optional;
 */
function ATSModal(props: IATSModalProps): ReactElement {
    const { title, icon, bodyText, buttons, type, handleClose, handleSetComment, bodyTextType, buttonsAlignment, commentValue, noClose } = props;
    const modalRef = useRef<HTMLDivElement>(null as any);

    const handleKeyClose = (e: KeyboardEvent): void => {
        if (e.keyCode === 27) props.handleClose();
    };

    useEffect(() => {
        document.body.classList.add("no-overflow");
        return () => document.body.classList.remove("no-overflow");
    }, []);

    useEventListener("keydown", handleKeyClose);

    const modals = {
        comment: <CommentModal handleClose={handleClose} handleSetComment={handleSetComment ?? handleClose} prevComment={commentValue} />,
        cancelWithComment: (
            <CancelWithCommentModal
                icon={icon ? icon : ""}
                title={title ? title : ""}
                bodyText={bodyText ? bodyText : [""]}
                handleClose={handleClose}
                handleSetComment={handleSetComment ?? handleClose}
                buttons={buttons ?? undefined}
            />
        ),
        default: (
            <DefaultModal
                title={title}
                icon={icon}
                bodyText={bodyText}
                buttons={buttons}
                bodyTextType={bodyTextType}
                buttonsAlignment={buttonsAlignment}
            />
        ),
        loading: <LoadingModal title={title} bodyText={bodyText} />,
    };

    return (
        <div className={styles.modal_wrapper} ref={modalRef}>
            <div className={styles.modal_container}>
                {!noClose && <i className={`${styles.modal_closeBtn} icon-close`} onClick={() => handleClose()}></i>}
                {modals[type]}
            </div>
        </div>
    );
}

export default ATSModal;
