import React, { ReactElement } from 'react'
import styles from "./ATSErrorMsg.module.scss"

interface ErrorMsgProps {
    errors: string[];
    customClass?: string;
}

function ATSErrorMsg(props: ErrorMsgProps): ReactElement {
    const errors = typeof props.errors === "string" ? [props.errors] : props.errors;

    if (!errors || errors.length === 0) return null as any;

    return (
        <>
            <div className={[styles.errorMsg, props.customClass || "", "danger"].join(" ")}>
                <i className="icon-error"></i>
                {errors.map((error: string, i: number) => <p key={i}>{error}</p>)}
            </div>
        </>
    )

}

export default ATSErrorMsg



