import { CardNumFormatter } from "../ATSCardInput/cardsInfo";
import { CardType } from "../ATSCardInput/Interfaces";

export interface IFormatter {
    setValue(val: string, character: string): void;
    format(val: string, ...params: any): string;
    validate(val: string): Array<string>;
}

export class CvvFormatter implements IFormatter {
    private cardType: CardType;

    constructor(cardNumber: string) {
        this.cardType = new CardNumFormatter().getCardType(cardNumber);
    }

    setValue(val: string, character: string): void {
        throw new Error("Method not implemented.");
    }

    validate(val: string): Array<string> {
        if (CardType.AMEX === this.cardType && val.length === 4)
            return [];
        else if (CardType.AMEX !== this.cardType && val.length === 3)
            return [];

        return ["Invalid CVV"];
    }

    format(val: string): string {
        let replaced = val.replace(/[^0-9\.]+/g, '');

        if (CardType.AMEX === this.cardType)
            return replaced.slice(0, 4);
        else
            return replaced.slice(0, 3);
    }
}