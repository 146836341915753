import { useEffect, useRef, useState } from "react";
import { AddPaymentRequest, AddPaymentResponse, PaymentMethods } from "../../../services/interfaces/IPayments";
import { ICourse } from "../../../services/interfaces/ICourse";
import { ATSTypeButton } from "../../../components/Shared/ATSButton";
import { ISingleDealerData } from "../../../services/interfaces/serviceResponses/dealer/ISingleDealerResponse";
import { copyJSON, currencyDollarUS } from "../../../helpers/helpers";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import StudentsStep from "./StudentsStep";
import StepsIndicator from "./StepsIndicator";
import PaymentStep from "./PaymentStep";
import StudentService from "../../../services/entitiesServices/StudentService";
import PurchaseInformation from "./PurchaseInformation";
import DealerService from "../../../services/entitiesServices/DealerService";
import SuccesPayment from "./SuccesStep";
import Layout from "../../../components/Layout";
import CourseStep from "./CourseStep";
import styles from "./EnrollStudents.module.scss";

export enum ATSSteps {
    NEXT,
    BACK,
}

export interface IBaseStepProps {
    handleInputChange: (name: string, value: string | number, _indexed?: string, _index?: number) => void;
    handleErrorsChange: (name: string, value: string[], _indexed?: string, _index?: number) => void;
    handleStep: (direction: ATSSteps) => void;
    setIsLoading?: (param: boolean) => void;
    isLoading?: boolean;
    state: any;
    errors: any;
}

const enrollDataInit = {
    courseInstanceId: null,
    dealerId: null,
    courseDaysInterval: null,
    slots: null,
    students: [],
    method: 1,
    paymentData: {
        cardNumber: "",
        cvc: "",
        expirationMonth: null,
        expirationYear: null,
        name: "",
        country: "",
        postalCode: "",
    },
    isDealer: false,
};

function EnrollStudents() {
    //Should be initialized with one type of payment, and send only one type of data for the payment.
    const [enrollData, setEnrollData] = useState<any>(enrollDataInit);
    const [errors, setErrors] = useState<any>({});
    const [steps, setSteps] = useState<number>(0);
    const [basePrice, setBasePrice] = useState<number>(0);
    const [selectedInstance, setSelectedInstance] = useState<ICourse | null>(null);
    const [dealer, setDealer] = useState<ISingleDealerData>();
    const [unexpectedErrorModal, setUnexpectedErrorModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [enrolledSucces, setEnrolledSucces] = useState<AddPaymentResponse | null>(null);
    const prevSlots = useRef<number | null>(0);

    const handleInputChange = (name: string, value: any, _indexed?: string, _index?: number): void => {
        if (name === "courseId" && value !== enrollData.courseId) {
            setEnrollData(prev => ({
                ...prev,
                locationId: null,
                courseInstanceId: null,
            }));
        }
        if (name === "locationId" && value !== enrollData.locationId) {
            setEnrollData(prev => ({
                ...prev,
                courseInstanceId: null,
            }));
        }
        if (name === "courseDaysInterval" && value !== enrollData.courseDaysInterval) {
            setEnrollData(prev => ({
                ...prev,
                locationId: null,
                courseInstanceId: null,
            }));
        }
        if (name === "slots") prevSlots.current = enrollData.slots;
        if (name === "method" && enrollData.method !== value) restorePayMethods(value);

        if (!_indexed) {
            setEnrollData(prev => ({
                ...prev,
                [name]: value,
            }));
        } else if (_indexed && (_index || _index === 0)) {
            let mutateState;
            let mutateIndexed;

            if (enrollData[_indexed]) {
                mutateIndexed = copyJSON(enrollData[_indexed]);
            } else {
                mutateState = copyJSON(enrollData);
                mutateState[_indexed] = [];
                mutateIndexed = copyJSON(mutateState[_indexed]);
            }

            mutateIndexed[_index] = {
                ...mutateIndexed[_index],
                [name]: value,
            };

            setEnrollData(prev => ({
                ...prev,
                [_indexed]: mutateIndexed,
            }));
        }
    };

    const handleErrorsChange = (name: string, value: string[], _indexed?: string, _index?: number): void => {
        if (!_indexed) {
            setErrors(prev => ({
                ...prev,
                [name]: value,
            }));
        } else if (_indexed && (_index || _index === 0)) {
            let mutateState;
            let mutateIndexed;

            if (errors[_indexed]) {
                mutateIndexed = copyJSON(errors[_indexed]);
            } else {
                mutateState = copyJSON(errors);
                mutateState[_indexed] = [];
                mutateIndexed = copyJSON(mutateState[_indexed]);
            }

            mutateIndexed[_index] = {
                ...mutateIndexed[_index],
                [name]: value,
            };

            setErrors(prev => ({
                ...prev,
                [_indexed]: mutateIndexed,
            }));
        }
    };

    const handleStep = (direction: ATSSteps) => {
        if (direction === ATSSteps.NEXT) {
            setSteps(prev => prev + 1);
        } else {
            setSteps(prev => prev - 1);
        }
    };

    const getDealerName = async dealerId => {
        setIsLoading(true);
        const response = await DealerService.getDealer(dealerId);
        if (response.success) {
            setIsLoading(false);
            setDealer(response.response);
        } else {
            setIsLoading(false);
            console.error(response.message);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            let payload = copyJSON(enrollData) as any;

            switch (payload.method) {
                case PaymentMethods.CreditCard: {
                    payload["creditCard"] = payload.paymentData;
                    break;
                }
                case PaymentMethods.ACH: {
                    payload["ach"] = payload.paymentData;
                    break;
                }
                case PaymentMethods.Manual: {
                    payload["manual"] = payload.paymentData;
                    break;
                }
            }

            delete payload["paymentData"];

            const response = await StudentService.enrollStudents(payload as AddPaymentRequest);
            if (response.success) {
                setEnrolledSucces(response.response);
                setSteps(3);
            } else {
                console.error(response.message);
                setErrorMessage(response.message);
                setUnexpectedErrorModal(!unexpectedErrorModal);
            }
        } catch (e) {
            //do something
        }
        setLoading(false);
    };

    const restorePayMethods = (method: PaymentMethods) => {
        let mutateState = copyJSON(enrollData);
        switch (method) {
            case PaymentMethods.CreditCard: {
                mutateState = {
                    ...mutateState,
                    paymentData: {
                        ...mutateState["paymentData"],
                        name: "",
                        cardNumber: "",
                        cvc: "",
                        expirationMonth: null,
                        expirationYear: null,
                        postalCode: "",
                    },
                };
                break;
            }
            case PaymentMethods.ACH: {
                mutateState = {
                    ...mutateState,
                    paymentData: {
                        accountNumber: "",
                        routingNumber: "",
                        holderName: "",
                        accountHolderType: "",
                        ipAddress: "",
                        userAgent: "",
                    },
                };
                break;
            }
            case PaymentMethods.Manual: {
                mutateState = {
                    ...mutateState,
                    paymentData: {
                        title: "",
                        comment: "",
                    },
                };
                break;
            }
        }

        setEnrollData(mutateState);
    };

    useEffect(() => {
        if (!enrollData.dealerId) {
            setIsLoading(false);
            return;
        }

        getDealerName(enrollData.dealerId);
    }, [enrollData.dealerId]);

    //delete students when slots change
    useEffect(() => {
        if (enrollData.slots !== 0 && !enrollData.slots) return;
        let quantity: number;
        //add slots if its true, else remove
        if (prevSlots.current === null || prevSlots.current < enrollData.slots) {
            quantity = enrollData.slots - (prevSlots.current ?? 0);
            let mutateState = copyJSON(enrollData.students);

            for (let i = 0; i < quantity; i++) {
                mutateState = [
                    ...mutateState,
                    {
                        firstName: "",
                        lastName: "",
                        email: "",
                        phoneNumber: "",
                    },
                ];
            }

            setEnrollData({
                ...enrollData,
                students: mutateState,
            });
        } else {
            quantity = prevSlots.current - enrollData.slots;
            let mutateState = copyJSON(enrollData.students).slice(0, -quantity);

            setEnrollData({
                ...enrollData,
                students: mutateState,
            });

            if (errors.students) {
                let mutateErrors = copyJSON(errors.students).slice(0, -quantity);
                setErrors({
                    ...errors,
                    students: mutateErrors,
                });
            }
        }
    }, [enrollData.slots]);

    const componentSteps = {
        0: (
            <CourseStep
                state={enrollData}
                {...{
                    handleInputChange,
                    handleErrorsChange,
                    handleStep,
                    setBasePrice,
                    selectedInstance,
                    setSelectedInstance,
                    errors,
                    prevSlots,
                    setIsLoading,
                    isLoading,
                }}
            />
        ),
        1: <StudentsStep state={enrollData} {...{ handleInputChange, handleErrorsChange, handleStep, errors }} />,
        2: (
            <PaymentStep
                state={enrollData}
                {...{ handleInputChange, handleErrorsChange, handleStep, errors, handleSubmit, setEnrollData, restorePayMethods }}
            />
        ),
    };

    return (
        <Layout
            title={"Enroll New Students"}
            backHistory={true}
            buttons={steps === 3 ? [{ cta: "Print Receipt", type: ATSTypeButton.DEFAULT, action: () => window.print() }] : []}>
            {steps !== 3 && (
                <div className={styles.layout}>
                    <div className={styles.layout_aside}>
                        <div className="whitebox">
                            <StepsIndicator totalSteps={["Course", "Students", "Payment"]} actualStep={steps} />
                        </div>

                        <div className={`cool ${styles.resume}`}>
                            <div className={styles.resume_column}>
                                <p>Course</p>
                                <p>Taxes</p>
                                <p>Total price</p>
                            </div>
                            <div className={styles.resume_column}>
                                <p>
                                    {basePrice !== 0 && enrollData.slots && enrollData.slots > 0
                                        ? `${enrollData.slots} x ${currencyDollarUS.format(basePrice)}`
                                        : "-"}
                                </p>
                                <p>-</p>
                                <p>{enrollData.slots ? currencyDollarUS.format(basePrice * enrollData.slots) : "-"}</p>
                            </div>
                        </div>
                        {enrollData.courseInstanceId !== null && (
                            <PurchaseInformation
                                instance={selectedInstance}
                                dealer={dealer?.dealer.dealerName ?? ""}
                                seats={enrollData.slots ?? null}
                            />
                        )}
                    </div>
                    <div className={styles.layout_content}>
                        <div className="whitebox hasScroll">
                            <form className="form">{componentSteps[steps]}</form>
                        </div>
                    </div>
                </div>
            )}
            {steps === 3 && (
                <SuccesPayment responsePayment={enrolledSucces} prevState={enrollData} courseInstance={selectedInstance} dealer={dealer} />
            )}
            {unexpectedErrorModal && (
                <ATSModal
                    title="ERROR"
                    icon="icon-error danger"
                    bodyText={["Sorry, your enrollment was not complete successfully.", `${errorMessage}`]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setUnexpectedErrorModal(!unexpectedErrorModal)}
                    buttons={[
                        {
                            cta: "Ok",
                            type: ATSTypeButton.DANGER,
                            action: () => setUnexpectedErrorModal(!unexpectedErrorModal),
                        },
                    ]}
                />
            )}
            {loading && (
                <ATSModal
                    title="Enrollment in process"
                    bodyText={["We are trying to enroll your students", `If you close this window, the process won't stop`]}
                    type={ATSTypeModal.LOADING}
                    noClose={true}
                    handleClose={() => setUnexpectedErrorModal(!unexpectedErrorModal)}
                />
            )}
        </Layout>
    );
}

export default EnrollStudents;
