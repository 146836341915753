import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import {
    CourseDropdownCheckOutRequest,
    CourseDropdownCheckOutResponse,
    CourseInstanceDropdownCheckOutRequest,
    CourseInstanceDropdownCheckOutResponse,
    CoursesDateIntervalResponse,
    LocationDropdownCheckOutRequest,
    LocationDropdownCheckOutResponse,
} from "../interfaces/ICheckout";

class CheckoutService extends ApiBaseService {
    public static async getCourses(body?: CourseDropdownCheckOutRequest): Promise<IResponse<CourseDropdownCheckOutResponse>> {
        const response = await this.securedPost<CourseDropdownCheckOutResponse>(`checkout/dropdownadmincourses`, body);
        return response;
    }

    public static async getLocations(body: LocationDropdownCheckOutRequest): Promise<IResponse<LocationDropdownCheckOutResponse>> {
        const response = await this.securedPost<LocationDropdownCheckOutResponse>(`checkout/dropdownadminlocations`, body);
        return response;
    }

    public static async getCourseInstances(
        body: CourseInstanceDropdownCheckOutRequest
    ): Promise<IResponse<CourseInstanceDropdownCheckOutResponse>> {
        const response = await this.securedPost<CourseInstanceDropdownCheckOutResponse>(`checkout/dropdownadmincourseinstances`, body);
        return response;
    }

    public static async getCoursesIntervalDate(): Promise<IResponse<CoursesDateIntervalResponse>> {
        const response = await this.securedGet<CoursesDateIntervalResponse>(`checkout/dropdownadmincoursesdateinterval`);
        return response;
    }
}

export default CheckoutService;
