import { cp } from "fs";
import { useState } from "react";
import ATSButton, { ATSTypeButton } from "../../ATSButton";
import styles from "../ATSModal.module.scss";

interface IATSModalCommentProps {
    prevComment?: string;
    handleClose: () => void;
    handleSetComment: (value: string) => void;
}

function CommentModal(props: IATSModalCommentProps) {
    const { handleClose, handleSetComment, prevComment } = props;
    const [comment, setComment] = useState(prevComment ?? "");

    const handleChange = e => {
        setComment(e.target.value);
    };

    return (
        <div className={styles.modal_content}>
            <div className={styles.modal_comment}>
                <label>Comment:</label>
                <textarea value={comment} onChange={handleChange} maxLength={200}></textarea>
            </div>
            <div className={styles.modal_btn_box}>
                <ATSButton cta={"Back"} type={ATSTypeButton.OUTPOSITIVE} action={() => handleClose()} />
                <ATSButton
                    disabled={prevComment === comment || comment === "" && !prevComment}
                    cta={prevComment ? "Edit" : "Add"}
                    type={ATSTypeButton.POSITIVE}
                    action={() => {
                        handleSetComment(comment);
                    }}
                />
            </div>
        </div>
    );
}

export default CommentModal;
