
export interface IFormatter {
    format(val: string, ...params: any): string;
    validate(val: string): Array<string>;
}

export class CardExpFormatter implements IFormatter {

    constructor() {
    }


    validate(val: string): Array<string> {
        if (!val.match(/\d{2}\/\d{2}/)) {
            return ["Expiration must be in format MM/YY"];
        }

        return [];
    }

    format(val: string): string {
        // -- Do nothing here
        if (!val || val == "")
            return "";
        // -- Take last char
        const lastChar = val.slice(val.length - 1);
        // -- Val without last char
        const preVal = val.slice(0, val.length - 1);
        // -- Only numbers and "/"
        if (val.match(/[^0-9/]+/g)) {
            let rep = val.replace(/[^0-9/]+/g, '');
            return rep;
        }
        // -- If val already have a "/" or is the first character
        if (lastChar === "/" && (preVal.includes("/") || !preVal || preVal.length === 0)) {
            return preVal;
        }
        // -- "/" typed or reached 3 characters
        if (lastChar === "/" || val.length === 3) {
            // -- Month
            const month = Number(preVal);
            // -- Not valid month
            if (month === 0 || month > 12) {
                return preVal;
            }
            // -- Valid month
            else {
                let ret = ('00' + preVal).slice(-2) + "/";
                if (val.length === 3 && lastChar !== "/") {
                    ret += lastChar;
                }
                return ret;
            }
        }
        // -- MM/YYYY
        if (val.length > 5) {
            return preVal;
        }

        return val;
    }
}