import { ATSBodyTextTypeModal, ATSButtonAlignmentModal } from "..";
import ATSButton, { IATSButtons } from "../../ATSButton";
import ATSSpinner from "../../ATSSpinner";
import styles from "../ATSModal.module.scss";

interface IATSModalDefaultProps {
    title?: string;
    icon?: string;
    bodyText?: Array<string>;
    buttons?: Array<IATSButtons>;
    bodyTextType?: ATSBodyTextTypeModal;
    buttonsAlignment?: ATSButtonAlignmentModal;
}

function LoadingModal(props: IATSModalDefaultProps) {
    const { title, bodyText } = props;

    return (
        <div className={styles.modal_content}>
            <ATSSpinner title={title} subtitle={`${bodyText?.map(x => x).join(". ")}`} />
        </div>
    );
}

export default LoadingModal;
