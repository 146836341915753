import { ATSBodyTextTypeModal, ATSButtonAlignmentModal } from "..";
import ATSButton, { IATSButtons } from "../../ATSButton";
import styles from '../ATSModal.module.scss';

interface IATSModalDefaultProps {
    title?: string,
    icon?: string,
    bodyText?: Array<string>,
    buttons?: Array<IATSButtons>
    bodyTextType?: ATSBodyTextTypeModal;
    buttonsAlignment?: ATSButtonAlignmentModal;
}

function DefaultModal(props: IATSModalDefaultProps) {
    const { title, icon, bodyText, buttons, bodyTextType, buttonsAlignment } = props

    return (
        <div className={styles.modal_content}>
            <h3 className={`${icon ? styles.modal_icontitle : styles.modal_title}`}>
                {icon && <i className={icon}></i>}
                {title}
            </h3>
            {bodyText && (
                <div className={styles.modal_textbox}>
                    {bodyText &&
                        bodyText.map((line, i) => (
                            <p key={`TextType-${i}`} className={bodyTextType && styles[bodyTextType]}>
                                {line}{" "}
                            </p>
                        ))}
                </div>
            )}
            <div className={`${styles.modal_btn_box} ${buttonsAlignment && styles[buttonsAlignment]}`}>
                {buttons && buttons.map((button, i) => <ATSButton {...{ ...button }} key={i + button.type} />)}
            </div>
        </div>
    );
}

export default DefaultModal