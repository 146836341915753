export const dictionary = {
    dealerName: "dealer",
    contactName: "contact",
    contactEmail: "contact email",
    addressLine1: "address line 1",
    addressLine2: "address line 2",
    zip: "zip code",
    notes: "comments",
    courseStatusName: "status",
    startTime: "Time",
    courseName: "course",
    statusName: "status",
    studentName: "name",
    studentEmail: "email",
    statusStudentName: "status",
    phoneNumber: "phone"

};
