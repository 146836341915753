import { Cards, CardType } from "./Interfaces";

export const cardsInfo: Cards = {
    list: [
        {
            brand: "American Express",
            image: "/images/creditcards/american-express.png",
            verification: "^3[47][0-9]",
            separation: "^([0-9]{4})([0-9]{6})?(?:([0-9]{6})([0-9]{5}))?$",
            hidden: "**** ****** *[0-9][0-9][0-9][0-9]",
            accepted: true,
            length: 15,
            type: CardType.AMEX
        },
        {
            brand: "MasterCard",
            image: "/images/creditcards/mastercard.png",
            verification: "^5[1-5][0-9]",
            separation: "^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$",
            hidden: "**** **** **** [0-9][0-9][0-9][0-9]",
            accepted: true,
            length: 16,
            type: CardType.MASTERCARD
        },
        {
            brand: "Visa",
            image: "/images/creditcards/visa.png",
            verification: "^4[0-9]",
            separation: "^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$",
            hidden: "**** **** **** [0-9][0-9][0-9][0-9]",
            accepted: true,
            length: 16,
            type: CardType.VISA
        },
        {
            brand: "Discover",
            image: "/images/creditcards/discover.png",
            verification: "^6(?:011|5[0-9]{2})[0-9]",
            separation: "^([0-9]{4})([0-9]{4})?([0-9]{4})?([0-9]{4})?$",
            hidden: "**** **** **** [0-9][0-9][0-9][0-9]",
            accepted: true,
            length: 16,
            type: CardType.DISCOVER
        },
    ],
};

export interface IFormatter {
    setValue(val: string, character: string): void;
    format(val: string, ...params: any): string;
    validate(val: string): Array<string>;
}

export class CardNumFormatter implements IFormatter {

    creditCards: Cards;
    active: number | null;
    prev: string | null;
    current: string;
    erasing: boolean;
    maxLength: number;

    constructor() {
        this.creditCards = cardsInfo;
        this.active = null;
        this.prev = null;
        this.current = "";
        this.erasing = false;
        this.maxLength = 16;
    }

    setValue(val: string, character: string = ""): void {

        this.prev = this.current;
        this.current = val + character;

        if (this.prev.length > this.current.length) {
            this.erasing = true;
        } else {
            this.erasing = false;
            const lastIsNumber = !isNaN(Number(val[val.length - 1])) && val[val.length - 1] != " ";

            if (!lastIsNumber && val.length != 5 && val.length != 10 && val.length != 12 && val.length != 15 && val.length != 17) {
                this.current = this.prev;
            }
        }

        if (this.current.length === this.maxLength) {
            this.prev = this.current;
        }

    }

    format(val: string): string {
        let card = val.replace(/[^0-9]/g, '');
        let prevActive = this.active;
        this.active = null;
        const cardIsNumber = /^\d+$/.test(val.replaceAll(" ", ""));
        if (!cardIsNumber && card !== "")
            return this.current;

        if (card.length <= this.maxLength) {
            this.setValue(val);
        } else {
            if (this.prev)
                return this.prev;
        }

        // return type of card to set logo img in input 
        if (!this.erasing) {
            if (card.length < this.maxLength) {
                for (let i = 0; i < this.creditCards.list.length; i++) {
                    let currentCard = this.creditCards.list[i];
                    if (card.replace(" ", "").match(new RegExp(currentCard.verification))) {
                        this.active = currentCard.type;
                        this.maxLength = currentCard.length === 15 ? (currentCard.length + 2) : (currentCard.length + 3);
                    }
                }

                //regex
                if (this.active != null) {
                    if (new RegExp(this.creditCards.list[this.active].separation).exec(card) != null) {
                        this.setValue(val + " ");
                    }
                } else {
                    //if this.active we haven't the type of card, so we need split it 4 by 4 bc that's a generic card
                    this.setValue(this.current.replace(/(\d{4}(?!\s))/g, "$1 "));
                }

            } else {
                if (this.prev) {
                    this.setValue(this.prev);
                    this.active = prevActive;
                }
            }
        } else {
            this.setValue(val);
            this.active = prevActive;
        }

        return this.current;
    }

    validate(val: string): Array<string> {
        // -- Clean
        let card = val.replace(/[^0-9]/g, '');
        for (let i = 0; i < this.creditCards.list.length; i++) {
            let currentCard = this.creditCards.list[i];
            // -- If match card type and length
            if (card.match(new RegExp(currentCard.verification)) && currentCard.length == card.length) {
                return [];
            }
        }

        return ["Invalid credit card"];
    }


    public getCardType(val: string): CardType {
        if (val){
            let card = val.replace(/[^0-9]/g, '');
            for (let i = 0; i < this.creditCards.list.length; i++) {
                let currentCard = this.creditCards.list[i];
                if (card.replace(" ", "").match(new RegExp(currentCard.verification))) {
                    return currentCard.type;
                }
            }
        }

        return CardType.UNKNOWN;
    }
}