import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from "react";
import ATSErrorMsg from "../ATSErrorMsg";
import { CvvFormatter } from "./cvvinfo";
import styles from "./ATSCvvInput.module.scss";

interface ICvvInputProps {
    name: string;
    label: string;
    cardNumber: string;
    required?: boolean;
    errors?: Array<string>;
    setErrorMessage?: (name: string, value: Array<string>) => void;
    handleChange(name: string, value: string): void;
}

const ATSCvvInput = (
    props: PropsWithChildren<ICvvInputProps>,
): ReactElement => {
    const { name, label, cardNumber, handleChange, errors, setErrorMessage } = props;

    const [formatter, setFormatter] = useState(new CvvFormatter(cardNumber));

    useEffect(() => {
        setFormatter(new CvvFormatter(cardNumber));
    }, [cardNumber]);


    const matchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedValue = formatter.format(e.target.value);

        e.target.value = formattedValue;
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let msg = formatter.validate(e.target.value);
        // -- Validate value
        if (setErrorMessage) setErrorMessage(name, msg);

        handleChange(name, e.target.value);
    };

    useEffect(() => {
        if (document && document.querySelector('#cvv') && (document.querySelector('#cvv') as HTMLInputElement).value != "")
            if (setErrorMessage) setErrorMessage(name, formatter.validate((document.querySelector('#cvv') as HTMLInputElement).value));
    }, [cardNumber]);

    return (
        <div className={`form_item ${styles.cvvWrapper} ${(errors && errors?.length > 0) ? "danger" : ''}`}>
            <label>{label}</label>
            <input
                type="text"
                onChange={e => matchHandler(e)}
                className={styles.cvv}
                onBlur={e => blurHandler(e)}
                id='cvv'
                name='cvv'
            />
            {(errors && errors?.length > 0) && <ATSErrorMsg errors={errors} />}
        </div>
    );
};

export default ATSCvvInput;
