import * as jwt from 'jose';

const useAuth = (): boolean => {
    let payload;
    let ls = localStorage.getItem("jwt-token")

    if ( ls && typeof(ls) === 'string') {
        try{
            const token = localStorage.getItem("jwt-token")
            const decodePayload = jwt.decodeJwt(token as string)
            payload = decodePayload
        }
        catch{
            return false
        }
    }

    if (payload && (payload.exp * 1000) > Date.now()) return true

    return false
};

export default useAuth



