import React, { ReactElement, useRef } from 'react'
import useEventListener from '../../../hooks/useEventListener';
import styles from "./ATSWarningMsg.module.scss"

interface WarningMsgProps {
    warnings: string[];
    customClass?: string;
    setWarnings: (param: string) => void;
}

function ATSWarning(props: WarningMsgProps): ReactElement {
    const warning = useRef<HTMLDivElement>(null as any)
    const warnings = typeof props.warnings === "string" ? [props.warnings] : props.warnings;

    if (!warnings || warnings.length === 0) return null as any;

    const handleClickOutside = (e: PointerEvent): void => {
        if (warning?.current && warning?.current.contains(e.target as Node))
            return;
        props.setWarnings('');
    };

    useEventListener("pointerdown", handleClickOutside);

    return (
        <>
            <div className={[styles.errorMsg, props.customClass || "", "warning"].join(" ")} ref={warning}>
                <i className="icon-warning"></i>
                {warnings.map((error: string, i: number) => <p key={i}>{error}</p>)}
            </div>
        </>
    )

}

export default ATSWarning



