import { config } from "../config";
import axios, { AxiosInstance } from "axios";
import AxiosService from "../axiosServices/AxiosService";

export default class ApiService extends AxiosService {
    // -- Override the instance
    protected static axiosInstance: AxiosInstance = this.getInstance();

    // -- Get instance
    protected static getInstance(): AxiosInstance {
        // -- Create axios instance
        const instance = axios.create({
            // -- Set base url
            baseURL: config.apiUrl
        });
        return instance;
    }
}