import { ReactElement, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import { objectValidations } from "../../../helpers/helpers";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../components/Shared/ATSModal";
import { PhoneNumFormatter } from "../../../components/form/ATSPhoneInput/phonenumberinfo";
import { ISingleStudentResponse } from "../../../services/interfaces/serviceResponses/student/ISingleStudentResponse";
import { IEditStudentRequest } from "../../../services/interfaces/servicePostBody/student/IEditStudentRequest";
import Layout from "../../../components/Layout";
import ATSEmailInput from "../../../components/form/ATSEmailInput";
import ATSPhoneInput from "../../../components/form/ATSPhoneInput";
import ATSSpinner from "../../../components/Shared/ATSSpinner";
import ATSInfoItem from "../../../components/Shared/ATSInfoItem";
import StudentService from "../../../services/entitiesServices/StudentService";
import styles from "./EditStudent.module.scss";
import ATSTextInput from "../../../components/form/ATSTextInput";

function EditStudent(): ReactElement {
    const StudentInit: ISingleStudentResponse = {
        id: 0,
        studentName: "",
        statusName: "",
        email: "",
        phone: "",
        courseName: "",
        courseInstanceLocation: "",
        startDate: "",
        endDate: "",
        startTime: 0,
        startTimeMinute: 0,
        dealerName: "",
        dealerEmail: "",
        paymentToken: "",
        paymentDate: "",
        amount: 0,
        pricePerUnit: 0,
        seatsBought: 0,
        comments: "",
        rejectComment: "",
        refundReason: "",
        courseId: 0,
        courseInstanceId: 0,
        courseInstanceLocationId: 0,
        paymentCourseName: "",
        methodId: 0,
        methodName: "",
        manualPaymentComment: "",
        paymentId: 0,
    };

    const studentFormInit = {
        studentId: 0,
        studentName: "",
        email: "",
        phone: "",
    };

    const navigate = useNavigate();
    const { studentId } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [unexpectedErrorModal, setUnexpectedErrorModal] = useState(false);
    const [studentForm, setStudentForm] = useState<IEditStudentRequest>(studentFormInit);
    const [errors, setErrors] = useState<Record<string, Array<string>>>({});
    const [response, setResponse] = useState<ISingleStudentResponse>(StudentInit);
    const [loading, setLoading] = useState<boolean>(false);

    const phoneFormatter = new PhoneNumFormatter();

    useEffect(() => {
        loadStudent();
    }, []);

    const loadStudent = async () => {
        setLoading(true);
        const res = await StudentService.getStudent(studentId);
        if (res.success) {
            const studentFormData: IEditStudentRequest = {
                studentId: res.response.id,
                studentName: res.response.studentName,
                email: res.response.email,
                phone: res.response.phone,
            };
            setResponse(res.response);
            setStudentForm(studentFormData);
        }
        setLoading(false);
    };

    const handleInputChange = (name: string, value: string | number): void => {
        setStudentForm(prev => ({
            ...prev,
            [name]: value,
        }));
    };
    const handleErrorsChange = (name: string, value: string[]): void => {
        setErrors(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    async function handleSubmit() {
        const validations = [
            {
                key: "studentName",
                type: "string",
                required: true,
            },
            {
                key: "email",
                type: "string",
                required: true,
            },
            {
                key: "phone",
                type: "string",
                required: true,
            },
        ];
        const properties = objectValidations(studentForm, validations);
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations) {
            try {
                const response = await StudentService.editStudent(studentForm as IEditStudentRequest);
                if (response.success) {
                    setOpenModal(!openModal);
                } else {
                    let errors;
                    response.validationErrors?.forEach(input => {
                        let key = input.field;
                        errors = {
                            ...errors,
                            [key]: input.messages,
                        };
                    });

                    setErrors(errors);
                    response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                    if (response.httpStatusCode === 500) setUnexpectedErrorModal(!unexpectedErrorModal);
                }
            } catch (e) {
                //do something
            }
        } else if (properties) {
            // show errors properties.inputs
            let errors;
            properties.inputs.forEach(input => {
                let key = input.key;
                let required = input.has;
                let valid = input.typeValid;
                let message;
                if (required && valid) return;
                if (!required && !valid) message = "This field is required, please provide a value";
                if (required && !valid) message = `This is not a accepted value`;
                errors = {
                    ...errors,
                    [key]: message,
                };
            });
            setErrors(errors);
            console.error("validations not passed");
        }
    }

    const buttons: IATSButtons[] = [
        {
            cta: "Save Changes",
            type: ATSTypeButton.NEGATIVE,
            action: () => handleSubmit(),
        },
    ];

    //#region modal's setup
    const buttonsModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.POSITIVE,
            url: `/students/${studentId}`,
        },
    ];

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setUnexpectedErrorModal(!unexpectedErrorModal),
        },
    ];
    //#endregion

    return (
        <Layout title={"Edit Contact Details"} backHistory={true} buttons={buttons}>
            {!loading ? (
                <div className={styles.layout}>
                    <div className={styles.layout_area}>
                        <div className="whitebox">
                            <ul className="whitebox_list">
                                <h2 className="form_title">Previous info</h2>
                                {response && <ATSInfoItem title={"NAME"} value={response.studentName} />}
                                {response && <ATSInfoItem title={"EMAIL"} value={response.email} />}
                                {response && <ATSInfoItem title={"PHONE NUMBER"} value={phoneFormatter.format(response.phone)} />}
                            </ul>
                        </div>
                    </div>
                    <div className={styles.layout_area}>
                        <div className="whitebox hasScroll">
                            <form className="form">
                                <fieldset className="form_fieldset maxWidth">
                                    <ATSTextInput
                                        label="Name"
                                        name="studentName"
                                        handleChange={handleInputChange}
                                        value={studentForm.studentName}
                                        error={errors && errors.studentName ? errors.studentName : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                    <ATSEmailInput
                                        label="Email"
                                        name="email"
                                        handleChange={handleInputChange}
                                        value={studentForm.email}
                                        error={errors && errors.email ? errors.email : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        maxLengthProps={100}
                                        required
                                    />
                                    <ATSPhoneInput
                                        label="Phone number"
                                        name="phone"
                                        handleChange={handleInputChange}
                                        value={studentForm.phone}
                                        error={errors && errors.phone ? errors.phone : []}
                                        setErrorMessage={(name, value) => handleErrorsChange(name, value)}
                                        required
                                    />
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <ATSSpinner />
            )}
            {openModal && (
                <ATSModal
                    title="Successfully updated"
                    icon="icon-check_circle_fill success"
                    bodyText={["The student contact info was successfully updated."]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => navigate(`/students/${studentId}`)}
                    buttons={buttonsModal}
                />
            )}
            {unexpectedErrorModal && (
                <ATSModal
                    title="ERROR"
                    icon="icon-error danger"
                    bodyText={[
                        "Sorry, the student was NOT updated successfully.",
                        "There was an unexpected error, check the information and try again.",
                        " If the problem persist call an administrator",
                    ]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setUnexpectedErrorModal(!unexpectedErrorModal)}
                    buttons={buttonsErrorModal}
                />
            )}
        </Layout>
    );
}

export default EditStudent;
