import { ReactElement, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ATSTypeButton } from "../../../../components/Shared/ATSButton";
import ATSInfo, { ATSInfoType } from "../../../../components/Shared/ATSInfo";
import { currencyDollarUS, formatDate, formatDateFull, getStartTime, timeConvert } from "../../../../helpers/helpers";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../../components/Shared/ATSModal";
import { ISingleStudentResponse } from "../../../../services/interfaces/serviceResponses/student/ISingleStudentResponse";
import ATSInfoItem from "../../../../components/Shared/ATSInfoItem";
import StudentService from "../../../../services/entitiesServices/StudentService";
import Layout from "../../../../components/Layout";
import styles from "./RefundStudentSuccess.module.scss";
import { PhoneNumFormatter } from "../../../../components/form/ATSPhoneInput/phonenumberinfo";

function RefundStudentSuccess(): ReactElement {
    const studentInit: ISingleStudentResponse = {
        id: 0,
        studentName: "",
        statusName: "",
        email: "",
        phone: "",
        courseName: "",
        courseInstanceLocation: "",
        startDate: "",
        endDate: "",
        startTime: 0,
        startTimeMinute: 0,
        dealerName: "",
        dealerEmail: "",
        paymentToken: "",
        paymentDate: "",
        methodId: 0,
        methodName: "",
        manualPaymentComment: "",
        amount: 0,
        pricePerUnit: 0,
        seatsBought: 0,
        comments: "",
        rejectComment: "",
        refundReason: "",
        paymentCourseName: "",
        courseId: 0,
        courseInstanceId: 0,
        courseInstanceLocationId: 0,
        paymentId: 0,
    };
    const { studentId } = useParams();
    const location = useLocation();
    const { refundAmount, pending } = location.state as any;
    const [response, setResponse] = useState<ISingleStudentResponse>(studentInit);
    const [modalConfig, setModalConfig] = useState<any>();
    const [toggleModal, setToggleModal] = useState<boolean>(false);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);
    const phoneFormatter = new PhoneNumFormatter();

    useEffect(() => {
        loadStudent();
    }, []);

    const loadStudent = async () => {
        try {
            setLayoutLoading(true);
            const response = await StudentService.getStudent(studentId);
            if (response && response.success && response.response) {
                setResponse(response.response);
            } else {
                response.httpStatusCode && console.error(response.httpStatusCode, response.message);
                if (response.httpStatusCode === 500) {
                    setModalConfig(refundStudentModals.unexpectedError);
                    setToggleModal(true);
                }
            }
            setLayoutLoading(false);
        } catch (e) {
            //do something
        }
    };

    const buttonsErrorModal = [
        {
            cta: "Ok",
            type: ATSTypeButton.DANGER,
            action: () => setToggleModal(false),
        },
    ];

    const buttonSuccessGoBack = [
        {
            cta: "Go back",
            type: ATSTypeButton.SUCCESS,
            url: `/students/${studentId}`,
        },
    ];

    const buttonWarningGoBack = [
        {
            cta: "Go back",
            type: ATSTypeButton.WARNING,
            url: `/students/${studentId}`,
        },
    ];

    const refundStudentModals = {
        unexpectedError: {
            title: "ERROR",
            icon: "icon-error danger",
            bodyText: ["Sorry there was an unexpected error while fetching the student data.", "If the problem persist call an administrator."],
            type: ATSTypeModal.DEFAULT,
            buttonsAlignment: ATSButtonAlignmentModal.CENTERED,
            handleClose: () => setToggleModal(false),
            buttons: buttonsErrorModal,
        },
    };

    return (
        <Layout
            title={"Cancel and Refund Student"}
            backHistory={false}
            backLabel="Student"
            buttons={[{ cta: "Print Receipt", type: ATSTypeButton.NEGATIVE, action: () => window.print() }]}
            isLoading={layoutLoading}>
            <div className={styles.layout}>
                <div className={styles.layout_aside}>
                    <div className="whitebox has_heading">
                        <div className="whitebox_heading">
                            <h3 className="whitebox_heading_title">Transaction</h3>
                        </div>
                        <div className="whitebox_content">
                            <ul className="whitebox_list inColumns">
                                {response && (
                                    <>
                                        <ATSInfoItem title={"TOKEN"} value={response.paymentToken} />
                                        <ATSInfoItem
                                            title={"AMOUNT REFUNDED"}
                                            value={refundAmount ? currencyDollarUS.format(refundAmount) : ""}
                                        />
                                        <ATSInfoItem title={"STATUS"} value={response.statusName} />
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={styles.layout_content}>
                    {!pending ? (
                        <ATSInfo
                            type={ATSInfoType.SUCCESS}
                            title="Your refund was successful, and your order is complete."
                            content={""}
                            buttons={buttonSuccessGoBack}
                        />
                    ) : (
                        <ATSInfo
                            type={ATSInfoType.WARNING}
                            title="Thanks for your order! Your refund is pending."
                            content={""}
                            buttons={buttonWarningGoBack}
                        />
                    )}
                    <div className="whitebox has_heading">
                        <div className="whitebox_heading">
                            <i className="icon-credit_card"></i>
                            <h2 className="whitebox_heading_title">Refund details</h2>
                        </div>
                        <div className="whitebox_content">
                            <div className="whitebox_area">
                                <h2 className="whitebox_heading_subtitle">Student</h2>
                                <ul className="whitebox_list inColumns">
                                    {response && (
                                        <>
                                            <ATSInfoItem title="Name" value={response.studentName} />
                                            <ATSInfoItem title="Email" value={response.email} />
                                            <ATSInfoItem title="Phone" value={phoneFormatter.format(response.phone)} />
                                        </>
                                    )}
                                </ul>
                            </div>

                            <div className="whitebox_area">
                                <h2 className="whitebox_heading_subtitle">Course info</h2>
                                <ul className="whitebox_list inColumns">
                                    <ATSInfoItem title="Course" value={response.courseName} />
                                    <ATSInfoItem title="Location" value={response.courseInstanceLocation} />
                                    <ATSInfoItem
                                        title="Start Date and Time"
                                        value={`${formatDate(response.startDate)} - ${timeConvert(
                                            getStartTime(response.startTime, response.startTimeMinute)
                                        )}`}
                                    />
                                    <ATSInfoItem title="Seats" value={`x ${response.seatsBought}`} />
                                    <ATSInfoItem title="Price" value={currencyDollarUS.format(response.pricePerUnit)} />
                                </ul>
                            </div>

                            <div className="whitebox_area">
                                <div className="whitebox_heading">
                                    <h2 className="whitebox_heading_subtitle">Original Payment</h2>
                                </div>
                                <ul className="whitebox_list inColumns">
                                    <ATSInfoItem title="Token" value={response.paymentToken ? response.paymentToken : "MANUAL ENROLLMENT"} />
                                    <ATSInfoItem title="Dealer" value={response.dealerName} />
                                    <ATSInfoItem title="Paid date" value={`${formatDateFull(response.paymentDate)}`} />
                                    <ATSInfoItem title="Method" value={response.methodName} />
                                    <ATSInfoItem title="Amount" value={currencyDollarUS.format(response.amount)} />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {toggleModal && <ATSModal {...{ ...modalConfig }} />}
        </Layout>
    );
}

export default RefundStudentSuccess;
