import { useState } from "react";
import ATSButton, { ATSTypeButton, IATSButtons } from "../../ATSButton";
import styles from "../ATSModal.module.scss";

interface IATSModalCancelProps {
    bodyText: Array<string>;
    handleClose: () => void;
    handleSetComment: (param: string) => void;
    icon: string;
    title: string;
    buttons?: Array<IATSButtons>;
}

function CancelWithCommentModal(props: IATSModalCancelProps) {
    const { bodyText, handleClose, handleSetComment, buttons } = props;
    const [comment, setComment] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleChange = e => {
        setComment(e.target.value);
        if (e.target.value.length > 0) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    };

    return (
        <div className={styles.modal_content}>
            <h3 className={styles.modal_icontitle}>
                <i className="icon-cancel danger"></i>
                {props.title}
            </h3>
            <div className={styles.modal_textbox}>{bodyText && bodyText.map(line => <p>{line} </p>)}</div>
            <div className={styles.modal_comment}>
                <label>Comment:</label>
                <textarea value={comment} onChange={handleChange} maxLength={200}></textarea>
            </div>
            <div className={styles.modal_btn_box}>
                {buttons && buttons.length > 0 ? (
                    buttons.map((button, i) => <ATSButton {...{ ...button }} key={i + button.type} />)
                ) : (
                    <>
                        <ATSButton cta={"Back"} type={ATSTypeButton.OUTPOSITIVE} action={() => handleClose()} />
                        <ATSButton cta={"Ok"} disabled={!buttonDisabled} type={ATSTypeButton.DANGER} action={() => handleSetComment(comment)} />
                    </>
                )}
            </div>
        </div>
    );
}

export default CancelWithCommentModal;
