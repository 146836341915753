import { useEffect, useRef } from "react";
import ATSErrorMsg from "../ATSErrorMsg";
import styles from "./ATSAccountNumberAchInput.module.scss";

interface IATSAccountNumberAchInputProps {
    name: string;
    value: string;
    label?: string;
    required?: boolean;
    placeholder?: string;
    resetValues?: boolean;
    error?: Array<string>;
    setErrorMessage?: (name: string, value: Array<string>) => void;
    handleChange: (name: string, value: string) => void;
    maxLength?: number;
}

/**
 * Create custom input text.
 * @param name string ;
 * @param value string;
 * @param label string ; -> optional
 * @param placeholder string ;
 * @param required boolean; -> Activate error on click outside without value
 * @param resetValues boolean-> Reset ATS input
 * @param error Array<string>;
 * @param setErrorMessage ( )=> void -> setState function that receives a name and value;
 * @param handleChange ( )=> void -> setState function that receive a name and value;
 */

const ATSAccountNumberAchInput = (props: IATSAccountNumberAchInputProps) => {
    const { name, value, label, placeholder, resetValues, required, handleChange, error, setErrorMessage, maxLength } = props;

    const refContainer = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (resetValues && refContainer.current) {
            refContainer.current.value = "";
        }
    }, [resetValues]);

    function validate(val: string): Array<string> {
        if (val.length === 12) return [];
        else return ["Invalid Account Number"];
    }

    function handleInputChange(value: string) {
        setErrorMessage && setErrorMessage(name, []);
        handleChange(name, value);
    }

    function handleBlurInput(value: string) {
        if (value === "" && required) {
            setErrorMessage && setErrorMessage(name, [`This field is mandatory and you did not provide a value.`]);
        } else {
            const msg = validate(value);
            setErrorMessage && setErrorMessage(name, msg);
        }
    }

    return (
        <div className={`form_item ${styles.wrapper} ${error && error?.length > 0 ? "danger" : ""}`}>
            {label && <label>{label}</label>}
            <input
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
                name={name}
                className={styles.wrapper_input}
                type="input"
                ref={refContainer}
                placeholder={placeholder}
                onChange={e => handleInputChange(e.target.value)}
                onBlur={e => handleBlurInput(e.target.value)}
                value={value}
                maxLength={maxLength}
            />
            {error && <ATSErrorMsg errors={error} />}
        </div>
    );
};

export default ATSAccountNumberAchInput;
