import React, { PropsWithChildren, ReactElement } from "react";
import ATSErrorMsg from "../ATSErrorMsg";
import styles from "./ATSEmailInput.module.scss";

interface IEmailInputProps {
    name: string;
    label?: string;
    required?: boolean;
    value?: string;
    className?: string;
    placeholder?: string;
    error?: Array<string>;
    setErrorMessage?: (name: string, value: Array<string>) => void;
    handleChange: (name: string, value: string) => void;
    maxLengthProps?: number;
}

const ATSEmailInput = (props: PropsWithChildren<IEmailInputProps>): ReactElement => {
    const { name, required, label, value, className, placeholder, handleChange, error, setErrorMessage, maxLengthProps } = props;

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let targetValue = e.target.value;
        if (!/\S+@\S+\.\S+/.test(targetValue)) setErrorMessage && setErrorMessage(name, ["The email has invalid format"]);

        if (!targetValue && required) {
            setErrorMessage && setErrorMessage(name, ["This field is mandatory and you did not provide a value."]);
            return;
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(name, e.target.value);
        setErrorMessage && setErrorMessage(name, []);
    };

    return (
        <>
            <div className={`form_item ${styles.emailWrapper}  ${error && error?.length > 0 ? "danger" : ""}`}>
                {label && <label>{label}</label>}
                <input
                    type="email"
                    name="email"
                    id="email"
                    className={`${className ? className : styles.email}`}
                    onBlur={e => blurHandler(e)}
                    onChange={handleInputChange}
                    value={value}
                    placeholder={`${placeholder ? placeholder : ""}`}
                    maxLength={maxLengthProps ?? 100}
                />
                {error && <ATSErrorMsg errors={error} />}
            </div>
        </>
    );
};

export default ATSEmailInput;
