import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface PrivateRouteProps {
	children: React.ReactNode;
}

export function PrivateRoute(props: PropsWithChildren<PrivateRouteProps>): JSX.Element {
	const { children } = props
	const token = useAuth();
	const location = useLocation()
	if (!token) {
		return <Navigate to="/login" state={{ from: location }} />
	}
	return (children as JSX.Element);
}

