import styles from './ATSSpinner.module.scss';

export interface IATSSPinnerProps {
    title?: string;
    subtitle?: string;
}

/**
 * Create loader spinner, you can set title and subtitle
 * @param title 
 * @param subtitle 
 * 
 */
function ATSSpinner(props: IATSSPinnerProps) {
    const { title, subtitle } = props;
    return (
        <div className={styles.spinner_container}>
            <div className={styles.spinner_wheel}></div>
            <h1 className={styles.spinner_title}>{title}</h1>
            <p className={styles.spinner_subtitle}>{subtitle}</p>
        </div>
    );
}

export default ATSSpinner;