import { useEffect, useState } from "react";
import { ATSSteps, IBaseStepProps } from "..";
import ATSNumberInput from "../../../../components/form/ATSNumberInput";
import ATSSelect, { SelectOption } from "../../../../components/form/ATSSelect";
import ATSButton, { ATSTypeButton } from "../../../../components/Shared/ATSButton";
import ATSModal, { ATSButtonAlignmentModal, ATSTypeModal } from "../../../../components/Shared/ATSModal";
import { formatDate, getStartTime, timeConvert } from "../../../../helpers/helpers";
import CheckoutService from "../../../../services/entitiesServices/CheckoutService";
import CourseService from "../../../../services/entitiesServices/CourseService";
import DealerService from "../../../../services/entitiesServices/DealerService";
import { ICourse } from "../../../../services/interfaces/ICourse";

interface ICourseStepProps extends IBaseStepProps {
    setBasePrice: (param: number) => void;
    selectedInstance: ICourse | null;
    setSelectedInstance: (param: ICourse | null) => void;
}

function CourseStep(props: ICourseStepProps) {
    const {
        handleInputChange,
        state,
        handleErrorsChange,
        errors,
        handleStep,
        setBasePrice,
        selectedInstance,
        setSelectedInstance,
        setIsLoading,
        isLoading,
    } = props;
    const [courseDropdowns, setCourseDropdown] = useState<SelectOption[]>();
    const [locationDropdowns, setLocationDropdown] = useState<SelectOption[]>();
    const [courseVisibilityDropdown, setCourseVisibilityDropdown] = useState<SelectOption[]>();
    const [instanceCourseDropdowns, setInstanceCourseDropdown] = useState<SelectOption[]>();
    const [dealersDropdowns, setDealersDropdowns] = useState<SelectOption[]>();
    const [unexpectedErrorModal, setUnexpectedErrorModal] = useState<Array<string> | null>(null);
    const [resetLocation, setResetLocation] = useState(false);
    const [resetInstance, setResetInstance] = useState(false);

    //#region Cascade Dropdowns and values -> state and fetchs
    const getCourseDropdown = async (daysInterval = 0) => {
        setIsLoading && setIsLoading(true);
        const response = await CheckoutService.getCourses({ daysInterval });
        if (response.success) {
            if (response.response.courses.length > 1) {
                setIsLoading && setIsLoading(false);
                setCourseDropdown(
                    response.response.courses.map(course => ({ value: course.id, label: course.name, default: state.courseId === course.id }))
                );
            } else {
                setIsLoading && setIsLoading(false);
                setCourseDropdown(response.response.courses.map(course => ({ value: course.id, label: course.name, default: true })));
            }
        } else {
            setIsLoading && setIsLoading(false);
            setUnexpectedErrorModal([
                "Sorry we had some issues to retrieve courses data",
                response.message,
                "If this problem persists please contact website admin",
            ]);
        }
    };

    const getLocationsDropdown = async (id, daysInterval = 0) => {
        setIsLoading && setIsLoading(true);
        const response = await CheckoutService.getLocations({ courseId: id, daysInterval });
        if (response.success) {
            setResetLocation(false);
            setResetInstance(false);
            if (response.response.locations.length > 1) {
                setIsLoading && setIsLoading(false);
                setLocationDropdown(
                    response.response.locations.map(location => ({
                        value: location.id,
                        label: location.name,
                        default: state.locationId === location.id,
                    }))
                );
            } else {
                setIsLoading && setIsLoading(false);
                setLocationDropdown(response.response.locations.map(location => ({ value: location.id, label: location.name, default: true })));
            }
        } else {
            setIsLoading && setIsLoading(false);
            setUnexpectedErrorModal([
                "Sorry we had some issues to retrieve locations data",
                response.message,
                "If this problem persists please contact website admin",
            ]);
        }
    };

    // Available
    const getAvailableTag = (total, occupied) => {
        if (total > occupied) return "Available";
        else return "At Capacity";
    };
    //

    const getInstanceDropdown = async (course, location, daysInterval) => {
        setIsLoading && setIsLoading(true);
        const response = await CheckoutService.getCourseInstances({
            courseId: course,
            locationId: location,
            daysInterval: daysInterval,
        });
        if (response.success) {
            setResetInstance(false);
            setIsLoading && setIsLoading(false);
            setInstanceCourseDropdown(
                response.response.instances.map(instance => ({
                    value: instance.id,
                    label: `${formatDate(instance.date)} ${timeConvert(
                        getStartTime(instance.startTime, instance.startTimeMinute)
                    )} ${getAvailableTag(instance.seats, instance.occupiedSeats)}`,
                    default: response.response.instances.length > 1 ? state.courseInstanceId === instance.id : true,
                }))
            );
        } else {
            setIsLoading && setIsLoading(false);
            setUnexpectedErrorModal([
                "Sorry we had some issues to retrieve courses data",
                response.message,
                "If this problem persists please contact website admin",
            ]);
        }
    };

    const getCourseVisibilityDropdown = async () => {
        setIsLoading && setIsLoading(true);
        const response = await CheckoutService.getCoursesIntervalDate();
        if (response.success) {
            setIsLoading && setIsLoading(false);
            setCourseVisibilityDropdown(
                response.response.coursesDateInterval.map((instance, i) => ({
                    value: instance.daysInterval,
                    label: `${instance.description}`,
                    default: i === 0,
                }))
            );
        } else {
            setIsLoading && setIsLoading(false);
            setUnexpectedErrorModal([
                "Sorry we had some issues retrieving courses interval dates",
                response.message,
                "If this problem persists please contact website admin",
            ]);
        }
    };

    const getCourseInstance = async courseId => {
        setIsLoading && setIsLoading(true);
        const response = await CourseService.getCourse(courseId);
        if (response && response.response) {
            setIsLoading && setIsLoading(false);
            setSelectedInstance(response.response.courseInstanceDataView);
        } else {
            setIsLoading && setIsLoading(false);
            setUnexpectedErrorModal([
                "Sorry we had some issues to retrieve courses data",
                response.message,
                "If this problem persists please contact website admin",
            ]);
        }
    };

    useEffect(() => {
        if (!state.courseId) return;
        setResetInstance(true);
        setResetLocation(true);
        setBasePrice(0);
        getLocationsDropdown(state.courseId, state.courseDaysInterval);
        handleInputChange("locationId", "");
        setSelectedInstance(null);
    }, [state.courseId]);

    useEffect(() => {
        setResetInstance(true);
        setSelectedInstance(null);
        if (state.locationId && state.courseId && typeof state.courseDaysInterval === "number") {
            getInstanceDropdown(state.courseId, state.locationId, state.courseDaysInterval);
        } else {
            setInstanceCourseDropdown([]);
        }
    }, [state.locationId]);

    useEffect(() => {
        if (!state.courseInstanceId) return;
        getCourseInstance(state.courseInstanceId);
    }, [state.courseInstanceId]);

    useEffect(() => {
        if (state.courseDaysInterval === null) return;
        setResetInstance(true);
        setSelectedInstance(null);
        setResetLocation(true);
        getCourseDropdown(state.courseDaysInterval);
        if (state.courseId) {
            getLocationsDropdown(state.courseId, state.courseDaysInterval);
        }
    }, [state.courseDaysInterval]);

    //#endregion

    const getDealersDropdown = async () => {
        setIsLoading && setIsLoading(true);
        const response = await DealerService.getDealers(1000, 0);
        if (response.success) {
            if (response.response.dealers.length > 1) {
                setIsLoading && setIsLoading(false);
                setDealersDropdowns(
                    response.response.dealers.map(dealer => ({ value: dealer.id, label: dealer.name, default: state.dealerId === dealer.id }))
                );
            } else {
                setIsLoading && setIsLoading(false);
                setDealersDropdowns(response.response.dealers.map(dealer => ({ value: dealer.id, label: dealer.name, default: true })));
            }
        } else {
            setIsLoading && setIsLoading(false);
            setUnexpectedErrorModal([
                "Sorry we had some issues to retrieve dealers data",
                response.message,
                "If this problem persists please contact website admin",
            ]);
        }
    };

    const nextStep = () => {
        if (state.courseInstanceId && state.slots && state.dealerId) handleStep(ATSSteps.NEXT);
    };

    useEffect(() => {
        if (!selectedInstance) return;
        setBasePrice(selectedInstance.price);
    }, [selectedInstance]);

    useEffect(() => {
        getCourseDropdown();
        getDealersDropdown();
        getCourseVisibilityDropdown();
    }, []);

    return (
        <>
            <fieldset className="form_fieldset maxWidth">
                <ATSSelect
                    name={"courseDaysInterval"}
                    options={courseVisibilityDropdown ?? []}
                    placeholder={"Select Courses Visibility Time Frame"}
                    label={"Date Range"}
                    handleChange={handleInputChange}
                    required
                    setErrorMessage={handleErrorsChange}
                    disabled={false}
                />
                {courseDropdowns && (
                    <ATSSelect
                        name={"courseId"}
                        options={courseDropdowns}
                        placeholder={"Select Course"}
                        label={"Course name"}
                        handleChange={handleInputChange}
                        required
                        setErrorMessage={handleErrorsChange}
                        error={errors?.courseId}
                    />
                )}
                <ATSSelect
                    name={"locationId"}
                    options={locationDropdowns ?? []}
                    placeholder={"Select Location"}
                    label={"Location"}
                    handleChange={handleInputChange}
                    required
                    setErrorMessage={handleErrorsChange}
                    error={errors?.locationId}
                    disabled={locationDropdowns ? false : true}
                    resetValues={resetLocation}
                />
                <ATSSelect
                    name={"courseInstanceId"}
                    options={instanceCourseDropdowns ?? []}
                    placeholder={"Select Start Date"}
                    label={"Start Date"}
                    handleChange={handleInputChange}
                    required
                    setErrorMessage={handleErrorsChange}
                    error={errors?.courseInstanceId}
                    disabled={instanceCourseDropdowns ? false : true}
                    resetValues={resetInstance}
                />
                <div className="form_fieldset row nopad colx2">
                    <ATSSelect
                        name={"dealerId"}
                        options={dealersDropdowns ?? []}
                        placeholder={"Select Dealer"}
                        label={"Dealer"}
                        handleChange={handleInputChange}
                        required
                        setErrorMessage={handleErrorsChange}
                        error={errors?.dealerId}
                    />
                    <ATSNumberInput
                        name={"slots"}
                        label={"Total Seats"}
                        value={state.slots ?? 0}
                        handleChange={handleInputChange}
                        min={0}
                        setErrorMessage={handleErrorsChange}
                        error={errors?.seats}
                        warning={
                            selectedInstance
                                ? `At this moment there are ${selectedInstance.seatsOccupied} seats occupied out of a total of ${selectedInstance.seats}. Attention you can add seats even if they exceed the total`
                                : ""
                        }
                        maxLengthProps={4}
                    />
                </div>
                <div className="form_fieldset row group btn-end">
                    <ATSButton
                        cta="Next"
                        type={ATSTypeButton.POSITIVE}
                        action={nextStep}
                        disabled={!state.courseInstanceId || !state.slots || !state.dealerId}
                        loading={isLoading}
                    />
                </div>
            </fieldset>
            {unexpectedErrorModal && (
                <ATSModal
                    title="ERROR"
                    icon="icon-error danger"
                    bodyText={unexpectedErrorModal}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setUnexpectedErrorModal(null)}
                    buttons={[{ cta: "Ok", type: ATSTypeButton.DANGER, action: () => setUnexpectedErrorModal(null) }]}
                />
            )}
        </>
    );
}

export default CourseStep;
