import { useEffect } from "react";
import { RadioOption } from "..";
import styles from "./RadioCard.module.scss";

interface RadioCardComponent {
    name: string;
    option: RadioOption,
    handleChange: (name: string, value: number) => void;
}

function RadioCardComponent(props: any) {
    const { option, handleChange, name } = props;

    useEffect(() => {
        if (!option.default) return;
        handleChange(name, option.value);
    }, []);

    return (
        <label htmlFor={option.value} className={styles.radioButton} onPointerDown={() => handleChange(name, option.value)} >
            <input id={option.value} type="radio" name={name} defaultChecked={option.default} />
            <button>
                <i className={option.classIcon}></i>
                <span>{option.label}</span>
            </button>
        </label>
    );
}

export default RadioCardComponent;