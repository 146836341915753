
import React, { PropsWithChildren, ReactElement } from 'react';
import { useState } from 'react';
import ATSErrorMsg from '../ATSErrorMsg';
import { CardExpFormatter } from './expirationinfo';
import styles from './ExpirationInput.module.scss';

interface IExpirationInputProps {
    name: string;
    label: string;
    required?: boolean;
    errors?: Array<string>;
    setErrorMessage?: (name: string, value: Array<string>) => void;
    handleChange(name: string, value: string): void;
}

const ATSExpirationInput = (props: PropsWithChildren<IExpirationInputProps>): ReactElement => {
    const { name, label, handleChange, errors, setErrorMessage } = props;

    const [formatter] = useState(new CardExpFormatter());

    const matchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedValue = formatter.format(e.target.value);
        e.target.value = formattedValue;
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let msg = formatter.validate(e.target.value);
        // -- Validate value
        if (setErrorMessage) setErrorMessage('expiration', msg);
        handleChange(name, e.target.value);
    };
    return (
        <div className={`form_item ${styles.expirationWrapper} ${(errors && errors?.length > 0) ? "danger" : ''}`}>
            <label>{label}</label>
            <input type="text" onChange={e => matchHandler(e)} className={styles.expiration} onBlur={e => blurHandler(e)} name={name} />
            {(errors && errors?.length > 0) && <ATSErrorMsg errors={errors} />}
        </div>
    );
};

export default ATSExpirationInput;